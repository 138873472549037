import { Advertising, type AdsSizeType } from '@/legacy/components/Advertising'
import { isAnchor } from '@/legacy/components/Advertising/utils'
import { type BackgroundColor, Container, type Space } from '@/legacy/components/Container'

interface AdsBlockProps {
  id?: string
  adUnitSlotId?: string
  type?: AdsSizeType
  space?: Space
  background: BackgroundColor
  backgroundColor?: string
  spaceIn?: Space
}
const AdsBlock = ({
  id,
  adUnitSlotId,
  type = 'leaderboard',
  space = 'm',
  background,
  backgroundColor,
  spaceIn,
}: AdsBlockProps) => {
  if (!adUnitSlotId) {
    return null
  }

  if (isAnchor(adUnitSlotId)) {
    return <Advertising adUnitSlotId={adUnitSlotId} type={type} />
  }

  const containerProps = {
    backgroundColor: background,
    backgroundColorHex: backgroundColor,
    spaceOut: space,
    id: id,
    spaceIn,
  }

  return (
    <Container {...containerProps}>
      <Advertising adUnitSlotId={adUnitSlotId} type={type} />
    </Container>
  )
}

export default AdsBlock
